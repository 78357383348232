import { ReactNode, Suspense } from "react";
import LanguageContext from "../../libs/context/LanguageContext";
import { IntlProvider } from "@progress/kendo-react-intl";
import { ESIntl, ItIntl } from "./intlSupportedLanguages";

// eslint-disable-next-line @typescript-eslint/naming-convention
const IntlProviderForKendoControls = (props: {
  children: Array<ReactNode> | ReactNode;
}): JSX.Element => {
  return (
    <LanguageContext.Consumer>
      {({ locale }) => {
        return (
          <>
            <Suspense fallback={<></>}>
              <IntlProvider locale={getLocaleToUseForKendoControls(locale)}>
                {/* lazy load locale specific intl data */}
                {locale === "es" && <ESIntl />}
                {locale === "it" && <ItIntl />}
                {/* render the childern nodes */}
                {props.children}
              </IntlProvider>
            </Suspense>
          </>
        );
      }}
    </LanguageContext.Consumer>
  );
};

/**
 * Get the locale to use for Kendo controls. If the locale is not supported by Kendo controls, then return "en" as the default locale.
 * @param locale  Current site locale.
 * @returns  The locale to use for Kendo controls.
 */
const getLocaleToUseForKendoControls = (locale: string): string => {
  switch (locale) {
    case "es":
    case "it":
      return locale;
    default:
      return "en";
  }
};
export default IntlProviderForKendoControls;
