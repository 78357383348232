/* eslint-disable @typescript-eslint/naming-convention */
export const ApiBaseUrl = process.env.REACT_APP_API_URL;
export const ReactAppBasepath = process.env.REACT_APP_BASEPATH;
export const FileUploadMaxFileSize = parseInt(
  process.env.REACT_APP_FILEUPLOAD_MAX_FILE_SIZE ?? "4000000"
);

export enum AccountEndPoints {
  Login = "/Auth/Login",
  Logout = "/Auth/Logout",
  RefreshToken = "/Auth/RefreshToken",
  LoginWithTwoStepVerification = "/Auth/LoginWithTwoStepVerification",
  GetTwoFactorAuthQRCode = "/Auth/GetTwoFactorAuthQRCode",
  GetActiveUserTwoFactor = "/User/GetActiveUserTwoFactor",
  SendPinToUserEmail = "/Auth/SendPinToUserEmail",
  UpdateTwoFactorLoginOption = "/User/UpdateTwoFactorLoginOption",
  GetUserPortalMenuItems = "/Auth/GetUserPortalMenuItems",
  LoginWithDirectAccessVerification = "/Auth/LoginWithDirectAccessVerification",
  GetPortalDirectAccessMenuItems = "/Auth/GetPortalDirectAccessMenuItems",
  GetClient = "/Client/GetClient",
  GetUserPreferredLocale = "/Auth/GetUserPreferredLocale",
}

export enum RegistrationEndPoints {
  GetRegistrationFormItemValues = "/Registration/GetRegistrationFormItemValues",
  Register = "/Registration/Register",
  ActivateAccount = "/Registration/ActivateAccount",
  DirectConsent = "/Registration/DirectConsent",
  RegisterPatient = "/Registration/RegisterPatient",
  GetPatientIdFieldValidationRegEx = "/Registration/GetPatientIdFieldValidationRegEx",
  ValidateOptOutLink = "/Registration/ValidateOptOutLink",
  SaveOptOutRequest = "/Registration/SaveOptOutRequest",
  HasConsented = "/Registration/HasConsented",
  ReferralRegistrationValidation = "/Registration/ReferralRegistrationValidation",
}

export enum UserEndPoints {
  GetLoggedInUser = "/User/GetLoggedInUser",
  UpdatePortalIdentity = "/User/UpdatePortalIdentity",
  UpdatePasswordHash = "/Auth/UpdatePasswordHash",
  ResetPassword = "/User/ResetPassword",
  CreateNewParticipant = "/User/CreateNewParticipant",
  EditParticipant = "/User/EditParticipant",
  GetUserGroupHierarchy = "/User/GetUserGroupHierarchy",
}

export enum ConsentEndPoints {
  GetConsentPdf = "/Consent/GetConsentPdf",
  GetConsentFormItems = "/Consent/GetConsentFormItems",
  DocumentViewer = "/Consent/DocumentViewer",
  DownloadConsentPdfFile = "/Consent/DownloadConsentPdfFile",
  CheckExistConsentPdfFile = "/Consent/CheckExistConsentPdfFile",
}

export enum UploadFilesEndPoints {
  GetDocumentUploadResult = "/UploadFile/GetDocumentUploadResult",
  UploadFiles = "/UploadFile/UploadFiles",
  DeleteFile = "/UploadFile/DeleteFile",
  DocumentViewer = "/UploadFile/DocumentViewer",
}

export enum SettingEndPoints {
  GetPortalParameterValueByName = "/Setting/GetPortalParameterValueByName",
  UpsertUserSetting = "/Setting/UpsertUserSetting",
  GetUserLanguageOptions = "/Setting/GetUserLanguageOptions",
  GetParticipantRecordSettings = "/Setting/GetParticipantRecordSettings",
  GetLocaleByLanguageId = "/Setting/GetLocaleByLanguageId",
}

export enum ReferenceDataPoints {
  GetCountry = "/ReferenceData/GetCountry",
  GetCounty = "/ReferenceData/GetCounty",
}

export enum ResourcesEndPoints {
  GetResources = "/Resources/GetResources",
  GetResourceHeadlines = "/Resources/GetResourceHeadlines",
  GetResourceNewsLetters = "/Resources/GetResourceNewsLetters",
  GetDBLockResource = "/Resources/GetDBLockResource",
  GetPermittedAnonymousResourcesByLocaleId = "/Resources/GetPermittedAnonymousResourcesByLocaleId",
  getCommentReviewOptions = "/Resources/GetCommentReviewOptions",
}

export enum SurveyEndPoints {
  GetSurveyDue = "/Survey/GetSurveyDue",
  GetClinicianAssessments = "/Survey/GetClinicianAssessments",
  GetVisitWindows = "/Survey/GetVisitWindows",
  GetSurveyCompleted = "/Survey/GetSurveyCompleted",
  GetSurveyTimeline = "/Survey/GetSurveyTimeline",
  SaveCommentReview = "/Survey/SaveCommentReview",
  GetSurveyListForReports = "/Survey/GetSurveyListForReports",
  GetSubmissions = "/Survey/GetSubmissions",
}

export enum DashboardEndPoints {
  GetTiles = "/Dashboard/GetTiles",
}

export enum ParticipantSearchEndpoints {
  GetParticipantId = "/User/GetParticipantId",
}

export enum ReportEndpoints {
  GetCommentReviewReport = "/Report/GetCommentReviewReport",
}

export enum GasEndpoints {
  SaveGasScoreRating = "/GasSurvey/SaveGasScoreRating",
  GetGasScoreDetails = "/GasSurvey/GetGasScoreDetails",
  SaveGasBuilderData = "/GasSurvey/SaveGasBuilderData",
  GetGasBuilderData = "/GasSurvey/GetGasBuilderData",
  SaveGoalAttainment = "/GasSurvey/SaveGoalAttainment",
  GetParticipantDetails = "/GasSurvey/GetParticipantDetails",
  GetGasSurveyData = "/GasSurvey/GetGasSurveyData",
  SaveAssessments = "/GasSurvey/SaveAssessments",
  GetGasCategoryHierarchy = "/GasSurvey/GetGasCategoryHierarchy",
  GetGasImportance = "/GasSurvey/GetGasImportance",
  GetGasDifficulty = "/GasSurvey/GetGasDifficulty",
}

export enum ParticipantDetailsEndPoint {
  GetIdentityUserDetails = "/User/GetIdentityUserDetails",
}
