import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CommentReviewReportFilterReduxModel,
} from "../../../libs/models/reportModel";

const initialState: CommentReviewReportFilterReduxModel = {
  scoreCardId: 0,
  startDate:  new Date(),
  endDate: new Date(),
  weekSelection: "",
};

const reportSearchFilterSlice = createSlice({
  name: "reportSearchFilterState",
  initialState,
  reducers: {
    onDateValueSelection: (state, action: PayloadAction<string>) => {
       state.weekSelection = "";
    },
    onReportSearchFilterSubmit: (state, action: PayloadAction<CommentReviewReportFilterReduxModel>) => {
      const actionData = action.payload;
      if (actionData.scoreCardId) {
        state.scoreCardId = actionData.scoreCardId;
      }
      if (actionData.startDate) {
        state.startDate = actionData.startDate;
      }
      if (actionData.endDate) {
        state.endDate = actionData.endDate;
      }
      if (actionData.weekSelection) {
        state.weekSelection = actionData.weekSelection;
      }
    },
  },
});
export const { onReportSearchFilterSubmit, onDateValueSelection } =
  reportSearchFilterSlice.actions;
export default reportSearchFilterSlice.reducer;
