import { ApplicationRoutes } from "../../components/routes/config";
import store from "../../store";
import * as Config from "../services/configEndPoints";
import responseConverterService from "../services/responseConverterService";
import signOutService from "../services/signOutService";

export type ApiResponse<T> = {
  message?: string;
  errorMessage?: string[];
  isError?: boolean;
  responseCode: number;
  data?: T;
  count?: number; // used in grid API calls to get the total records for paging
};

const getAuthToken = () => {
  let tokenString: string | null = sessionStorage.getItem("token");
  if (tokenString == null) tokenString = "{}";
  const userToken = JSON.parse(tokenString);
  return userToken?.token ?? "";
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getHttpHeaders = () => {
  let clientSettingsString: string | null = sessionStorage.getItem("ClientApp");
  if (clientSettingsString == null) clientSettingsString = "{}";
  const clientSettings = JSON.parse(clientSettingsString);
  const siteLanguage =
    store
      .getState()
      .app.siteLanguage.find(
        (t) => t.key === clientSettings.AppName?.toLowerCase()
      )?.value ?? "";

  return {
    "Content-Type": "application/json",
    Client: clientSettings.AppName,
    Authorization: "Bearer " + getAuthToken(),
    "Accept-Language": siteLanguage,
  };
};

export const get = async <TResponse>(
  endpoint: string
): Promise<ApiResponse<TResponse>> => {
  try {
    let request = await fetch(Config.ApiBaseUrl + endpoint, {
      method: "GET",
      headers: getHttpHeaders(),
    });

    if (request.status === 401) {
      request = await handle401(request, endpoint, "GET");
    }

    return await responseConverterService.convertToApiResponse(request);
  } catch {
    return Promise.resolve({ responseCode: 400 });
  }
};

export const post = async <TBody, TResponse>(
  endpoint: string,
  body: TBody
): Promise<ApiResponse<TResponse>> => {
  try {
    let request = await fetch(Config.ApiBaseUrl + endpoint, {
      method: "POST",
      headers: getHttpHeaders(),
      body: JSON.stringify(body),
      credentials: "include",
    });

    if (request.status === 401) {
      request = await handle401(
        request,
        endpoint,
        "POST",
        JSON.stringify(body)
      );
    }

    return await responseConverterService.convertToApiResponse(request);
  } catch {
    return Promise.resolve({ responseCode: 400 });
  }
};

async function handle401(
  request: Response,
  endpoint: string,
  requestType: "POST" | "GET",
  bodyJSON?: string
) {
  const resp = await refreshToken();
  if (resp.responseCode === 200) {
    sessionStorage.setItem("token", JSON.stringify({ token: resp.data }));
    request = await retryRequest(endpoint, requestType, bodyJSON);
  } else {
    signOutService.signoutRedirectCustom();
  }
  return request;
}

async function retryRequest(
  endpoint: string,
  requestType: "POST" | "GET",
  bodyJSON?: string
) {
  // resend intial request again
  return await fetch(Config.ApiBaseUrl + endpoint, {
    method: requestType,
    headers: getHttpHeaders(),
    body: bodyJSON,
  });
}

async function refreshToken() {
  const resp = await fetch(
    Config.ApiBaseUrl + Config.AccountEndPoints.RefreshToken,
    {
      method: "POST",
      headers: getHttpHeaders(),
      body: JSON.stringify(getAuthToken()),
      credentials: "include",
    }
  );
  return await responseConverterService.convertToApiResponse(resp);
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const development: boolean =
  !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === "development";

export function isDevelopment(): boolean {
  // console.log(
  //   "process.env.REACT_APP_ENV",
  //   process.env.REACT_APP_ENV,
  //   development,
  //   process.env.NODE_ENV
  // );

  return development;
}

export const getNiceBytes = (value: string): string => {
  const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let l = 0,
    n = parseInt(value, 10) || 0;
  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return Math.ceil(n).toFixed(0) + " " + units[l];
};

export const isUserPermittedToAccess = (
  pagePermissions: Array<string>,
  userPermissions: Array<string>
): boolean => {
  // 1. If we are in development mode return true (do not restrict any page)
  // 2. Otherwise, If any of the page permissions are found in the user's permissions return true, else return false
  return isDevelopment()
    ? true
    : userPermissions.some((r) => pagePermissions.indexOf(r) >= 0);
};

export const getCompletionUrl = (encryptedConifgData: string): string => {
  return (
    ApplicationRoutes.Completion +
    "?r=" +
    window.location.pathname.replace(/\/icportal/i, "") +
    "&data=" +
    encryptedConifgData
  );
};

export const getAutoCompleteValue = (): string => {
  return navigator.userAgent.match(/chrome|chromium|crios/i) // Chrome
    ? "disabled"
    : "off";
};
