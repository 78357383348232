import { AccountEndPoints } from "./configEndPoints";
import { post, ApiResponse } from "../helpers";
import {
  Credentials,
  TwoFactorQRCode,
  ValidateQRCode,
  TwoFactorUser,
  ClientDetails,
  PortalUserAccessDetail,
  UserAuthResponse,
} from "../models/accountModel";

const loginUser = async (
  credentials: Credentials
): Promise<ApiResponse<UserAuthResponse>> => {
  return post<Credentials, UserAuthResponse>(AccountEndPoints.Login, credentials);
};

const logoutUser = async (): Promise<ApiResponse<void>> => {
  return post<void, void>(AccountEndPoints.Logout, undefined as void);
};

const sendPinToUserEmail = async (
  userName: string
): Promise<ApiResponse<string>> => {
  return post<string, string>(AccountEndPoints.SendPinToUserEmail, userName);
};

const updateTwoFactorLoginOption = async (
  twoFactorUser: TwoFactorUser
): Promise<ApiResponse<string>> => {
  return post<TwoFactorUser, string>(
    AccountEndPoints.UpdateTwoFactorLoginOption,
    twoFactorUser
  );
};

const twoStepLoginVerification = async (
  validateQRCode: ValidateQRCode
): Promise<ApiResponse<string>> => {
  return post<ValidateQRCode, string>(
    AccountEndPoints.LoginWithTwoStepVerification,
    validateQRCode
  );
};

const getTwoFactorAuthQRCode = async (
  twoFactorQRCode: TwoFactorQRCode
): Promise<ApiResponse<string>> => {
  return post<TwoFactorQRCode, string>(
    AccountEndPoints.GetTwoFactorAuthQRCode,
    twoFactorQRCode
  );
};

const getActiveUserTwoFactor = async (
  userName: string
): Promise<ApiResponse<TwoFactorUser>> => {
  return post<string, TwoFactorUser>(
    AccountEndPoints.GetActiveUserTwoFactor,
    userName
  );
};

const getUserPortalMenuItems = async (
  userName: string
): Promise<ApiResponse<PortalUserAccessDetail>> => {
  return post<string, PortalUserAccessDetail>(
    AccountEndPoints.GetUserPortalMenuItems,
    userName
  );
};

const loginWithDirectAccessVerification = async (
  directAccessToken: string
): Promise<ApiResponse<string>> => {
  return post<string, string>(
    AccountEndPoints.LoginWithDirectAccessVerification,
    directAccessToken
  );
};

const getPortalDirectAccessMenuItems = async (): Promise<
  ApiResponse<PortalUserAccessDetail>
> => {
  return post<string, PortalUserAccessDetail>(
    AccountEndPoints.GetPortalDirectAccessMenuItems,
    ""
  );
};

const getClient = async (
  localeId: string
): Promise<ApiResponse<ClientDetails>> => {
  return post<string, ClientDetails>(AccountEndPoints.GetClient, localeId);
};

const getUserPreferredLocale = async (
): Promise<ApiResponse<string>> => {
  return post<void, string>(
    AccountEndPoints.GetUserPreferredLocale,
    undefined as void
  );
};
// eslint-disable-next-line @typescript-eslint/naming-convention
const AccountApi = {
  loginUser,
  logoutUser,
  twoStepLoginVerification,
  getTwoFactorAuthQRCode,
  getActiveUserTwoFactor,
  sendPinToUserEmail,
  updateTwoFactorLoginOption,
  getUserPortalMenuItems,
  loginWithDirectAccessVerification,
  getPortalDirectAccessMenuItems,
  getClient,
  getUserPreferredLocale,
};

export default AccountApi;
