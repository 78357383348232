import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { KeyValue } from "./libs/models/commonModel";
import { CommentReviewDetails } from "./libs/models/surveyModel";
import { RegistrationData } from "./libs/models/registrationModel";

type AppState = {
  theme: KeyValue[];
  siteLanguage: KeyValue[];
  portalDefaultLanguage: KeyValue[];
  commentReviewDetails: CommentReviewDetails;
  registrationData: RegistrationData;
};

const initialState: AppState = {
  theme: [],
  siteLanguage: [],
  portalDefaultLanguage: [],
  commentReviewDetails: {
    isCommentReviewReportLoaded: false,
  },
  registrationData: {
    subjectId: "",
    registrationType: undefined,
  },
};

const appSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setTheme(state, action: PayloadAction<KeyValue>) {
      const keyIndex = state.theme.findIndex(
        (t) => t.key === action.payload.key?.toLowerCase() ?? ""
      );
      if (keyIndex !== -1) {
        state.theme[keyIndex].value = action.payload.value;
      } else {
        state.theme.push({
          key: action.payload.key?.toLowerCase() ?? "",
          value: action.payload.value,
        });
      }
    },
    setSiteLanguage(state, action: PayloadAction<KeyValue>) {
      const keyIndex = state.siteLanguage.findIndex(
        (t) => t.key === action.payload.key?.toLowerCase() ?? ""
      );
      if (keyIndex !== -1) {
        state.siteLanguage[keyIndex].value = action.payload.value;
      } else {
        state.siteLanguage.push({
          key: action.payload.key?.toLowerCase() ?? "",
          value: action.payload.value,
        });
      }
    },
    setPortalDefaultLanguage(state, action: PayloadAction<KeyValue>) {
      const keyIndex = state.portalDefaultLanguage.findIndex(
        (t) => t.key === action.payload.key?.toLowerCase() ?? ""
      );
      if (keyIndex !== -1) {
        state.portalDefaultLanguage[keyIndex].value = action.payload.value;
      } else {
        state.portalDefaultLanguage.push({
          key: action.payload.key?.toLowerCase() ?? "",
          value: action.payload.value,
        });
      }
    },
    setCommentReviewDetails(
      state,
      action: PayloadAction<CommentReviewDetails>
    ) {
      state.commentReviewDetails = action.payload;
    },
    myDataViewEditSurveyButtonClicked(state) {
      setCommentReviewReportLoadedStateToFalse(state);
    },
    mySurveyViewEditSurveyButtonClicked(state) {
      setCommentReviewReportLoadedStateToFalse(state);
    },
    viewSubmissionViewEditSurveyButtonClicked(state) {
      setCommentReviewReportLoadedStateToFalse(state);
    },
    timeLineViewEditSurveyButtonClicked(state) {
      setCommentReviewReportLoadedStateToFalse(state);
    },
    setOnLoadRegistrationData(state, action: PayloadAction<RegistrationData>) {
      state.registrationData = action.payload;
    },
    clearOnLoadRegistrationData(
      state,
      action: PayloadAction<RegistrationData>
    ) {
      state.registrationData = action.payload;
    },
  },
});

function setCommentReviewReportLoadedStateToFalse(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any
) {
  state.commentReviewDetails = {
    isCommentReviewReportLoaded: false,
  };
}

export const {
  setTheme,
  setSiteLanguage,
  setPortalDefaultLanguage,
  setCommentReviewDetails,
  myDataViewEditSurveyButtonClicked,
  setOnLoadRegistrationData,
  clearOnLoadRegistrationData,
  mySurveyViewEditSurveyButtonClicked,
  viewSubmissionViewEditSurveyButtonClicked,
  timeLineViewEditSurveyButtonClicked,
} = appSlice.actions;

export default appSlice.reducer;
