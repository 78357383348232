import { ApiResponse } from "../helpers";

const convertToApiResponse = async <TResponse>(request: Response): Promise<ApiResponse<TResponse>> => {
    const response: ApiResponse<TResponse> = await request.json();
    response.responseCode = request.status;
    return response;
}

const responseConverterService = {
    convertToApiResponse
}

export default responseConverterService;