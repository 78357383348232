/* eslint-disable @typescript-eslint/no-explicit-any */
import { configureStore, Action } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { ThunkAction } from "redux-thunk";
import rootReducer, { RootState } from "./rootReducer";
import { createMigrate, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

const migrations = {
  // version 0 changes
  0: (state: any) => {
    return {
      ...state,
      // version 0, has a new property registrationData which was not in verion -1 (default version)
      app: { ...state.app, registrationData: { subjectId: "" } },
    };
  },
};

const persistConfig = {
  key: "root.portal", // set a key to this data, this should be different to the admin app otherwise both will refer to the same and can cause issues
  storage,
  version: 0, // current version in use, default version numbering starts from -1
  migrate: createMigrate(migrations),
  blacklist: [
    "reportSearchFilterState",
    "dataGridState",
    "referralRegistrationState",
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
      },
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
